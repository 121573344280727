@import "./vars";
@import "@material/typography/mdc-typography";

.stepper {
  $stepper: &;

  &__content {
    padding: $stepper-padding $stepper-padding 0 $stepper-padding;

    &__actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: $stepper-action-height-horizontal;
    }
  }

  &--vertical {
    #{$stepper}__content {
      padding: ($stepper-padding / 2) $stepper-padding $stepper-padding ($stepper-padding / 2);

      &__actions {
        margin-top: 16px;
        height: $stepper-action-height-vertical;
      }
    }
  }
}
