@import './vars';
@import "@material/button/mdc-button";

.stepper {
  .stepper__action {
    &.mdc-button--unelevated {
      color: mdc-theme-prop-value(text-primary-on-dark);
    }

    &:not([disabled]) {
      cursor: pointer;
    }

    + .stepper__action {
      margin: 0;
      margin-right: ($stepper-padding / 2);
    }

    &.mdc-button-align--right {
      margin-left: auto;

      + .mdc-button-align--right {
        margin: 0;
        margin-left: ($stepper-padding / 2);
      }
    }
  }
}
